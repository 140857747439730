<template>
    <div class="container-fluid">
        <div class="col-md-12">
            <h4 class="title-in-h">{{info_page.header}}</h4>
        </div>
        <breadcrumb >
            <breadcrumb-item  v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                <router-link :to="{path:breadcrumb.path}">{{breadcrumb.name}}</router-link>
            </breadcrumb-item>
            <breadcrumb-item active v-else>
                {{breadcrumb.name}}
            </breadcrumb-item>
        </breadcrumb>

        <card class="mt-2">

        <div>
            <!--   Hacer el cambio de idioma
           <div class="row d-none">
                <div class="col-md-8">
                    <div class="btn-taps text-center mt-2 mb-1">

                        <button v-for="i_lang in info_page.langs" class="btn btn-wd btn-default ml-1 mr-1" :class="info_page.tab_lang===i_lang.locale?'btn-info':'btn-outline'" @click="info_page.tab_lang=i_lang.locale">{{i_lang.name}}</button>

                    </div>
                </div>
            </div>  ***************************      -->


            <form>
                <div class="row">

                    <div class="col-md-4">
                        <fg-input type="text"
                                  :name="$t('form.name')"
                                  :label="$t('form.name')"
                                  :placeholder="$t('form.name')"
                                  v-validate="modelValidations.name"
                                  :error="getError($t('form.name'))"
                                  v-model="contract.es.name">
                        </fg-input>
                       <!-- <fg-input type="text"
                                  :name="$t('form.name_lang')"
                                  :label="$t('form.name_lang')"
                                  :placeholder="$t('form.name_lang')"

                                  :error="getError($t('form.name_lang'))"
                                  v-show="info_page.tab_lang==='en'"
                                  v-model="contract.en.name">
                        </fg-input>-->

                    </div>
                    <div class="col-md-4">
                        <fg-input type="text"
                                  :name="$t('contract.form.code')"
                                  :label="$t('contract.form.code')"
                                  :placeholder="$t('contract.form.code')"
                                  v-validate="modelValidations.code"
                                  :error="getError($t('contract.form.code'))"
                                  v-model="contract.cod">
                        </fg-input>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('contract.form.type')}}</label>
                            <el-select class="select-default" size="large" :placeholder="$t('contract.form.type')"  v-validate="modelValidations.type" :name="$t('contract.form.type')"
                                       v-model="contract.meal_plan"  :error="getError($t('contract.form.type'))">
                                <el-option v-for="option in contract_info.types" class="select-default" :value="option._id"
                                           :label="option.name" :key="option._id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('contract.form.currency')}}</label>
                            <el-select class="select-default" size="large" :placeholder="$t('contract.form.currency')"  v-validate="modelValidations.currency" :name="$t('contract.form.currency')"
                                       v-model="contract.currency"  :error="getError($t('contract.form.currency'))">
                                <el-option v-for="option in contract_info.currencys" class="select-default"  :value="option._id"
                                           :label="option.name" :key="option._id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="container-tags">
                            <label class="title">{{$t('contract.form.asign')}}</label>
                            <div class="box-inside">
                                <div class="box-left">
                                    <input type="text" :placeholder="$t('form.filter')"
                                           class="form-control"
                                           v-model="fplugin.rooms.input_search"
                                           @keyup = "fplugin.rooms.array_list = find_item(fplugin.rooms.input_search, contract_info.rooms)">
                                    <div class="box-items">
                                        <label v-for="room in fplugin.rooms.array_list"
                                               @click="addArray(room, contract.rooms)">{{room.name}}</label>
                                    </div>
                                </div>
                                <div class="box-right">
                                    <el-tag
                                            :key="idx_key"
                                            v-for="(tag, idx_key) in contract.rooms"
                                            size="small"
                                            type="default"
                                            :closable="true"
                                            class="custom"
                                            :close-transition="true"
                                            @close="handleClose(idx_key, contract.rooms)">
                                        {{tag.name}}
                                    </el-tag>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                        <div class="col-md-4">
                            <label >{{$t('contract.form.type_payment')}}</label>
                            <div class="form-check"  v-for="payment in contract_info.payments">
                                <label :for="payment._id" class="form-check-label">
                                    <input class="form-check-input" type="checkbox" :id="payment._id" v-model="contract.payment_options" :value="payment._id">
                                    <span class="form-check-sign" ></span>{{payment.name}}

                                </label>
                            </div>
                                <!--<span>Checked names: {{ contract.payment_options }}</span>-->

                        </div>

                   <!-- <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('room.form.type_room')}}</label>
                            <el-select class="select-default" size="large" :placeholder="$t('room.form.type_room')"  v-validate="modelValidations.type_room" :name="$t('room.form.type_room')"
                                       v-model="room.type_room"  :error="getError($t('room.form.type_room'))">
                                <el-option v-for="option in room_info.types" class="select-default" :value="option.label"
                                           :label="option.label" :key="option.label">
                                </el-option>
                            </el-select>
                        </div>
                    </div>-->
                    <div class="col-md-4">

                    </div>
                </div>


                <div class="row" v-if="contract_info.contract_all.length">

                    <div class="col-md-4">
                        <div class="form-group has-label">
                            <label >{{$t('contract.form.vinculo')}}</label>
                            <el-select class="select-default" size="large" :placeholder="$t('contract.form.vinculo')"
                                       v-model="contract.link_rateplan" >
                                <el-option v-for="option in room_info.contracts" class="select-default" :value="option.label"
                                           :label="option.label" :key="option.label">
                                </el-option>
                            </el-select>
                        </div>

                    </div>
                    <div class="col-md-4  pt-custom">
                        <fg-input type="text"
                                  :name="$t('room.form.code')"
                                  :placeholder="$t('room.form.code')"
                                  v-model="contract.link_value">
                        </fg-input>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group has-label pt-custom">
                            <el-select class="select-default" size="large" :placeholder="$t('room.form.type_room')"  :name="$t('room.form.type_room')"
                                       v-model="contract.link_type"  :error="getError($t('room.form.type_room'))">
                                <el-option v-for="option in contract_info.format_payment" class="select-default" :value="option._id"
                                           :label="option._id" :key="option.name">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>

                <!--<div class="row">

                    <div class="col-md-2">
                        <label> test </label>

                    </div>
                    <div class="col-md-4">
                        <fg-input type="text"
                                  :name="$t('room.form.code')"
                                  :placeholder="$t('room.form.code')"
                                  v-validate="modelValidations.code"
                                  :error="getError($t('room.form.code'))"
                                  v-model="room.code">
                        </fg-input>
                    </div>
                    <div class="col-md-6">

                    </div>
                </div>-->







                <div class="text-center mt-3 mb-3">
                    <button type="submit" class="btn btn-info btn-fill btn-wd mr-2" @click.prevent="sendForm(info_page.action)">
                        {{info_page.button}}
                    </button>
                    <button type="buttton" class="btn btn-danger btn-fill btn-wd" @click.prevent="$router.push({name: 'Contracts'})">
                        {{$t('form.cancel')}}
                    </button>
                </div>
                <div class="clearfix"></div>
            </form>
        </div>
    </card>
    </div>
</template>
<script>
    import swal from 'sweetalert2'
    import {Select, Option, Tag} from 'element-ui'
    import { Checkbox, Breadcrumb, BreadcrumbItem } from 'src/components/index'
    import PropertyService from '../../../js/services/PropertyService';
    import ContractService from '../../../js/services/ContractService';
    import {VTab, VueTabs} from 'vue-nav-tabs'
    import Fuse from 'fuse.js'


    const propertyService = new PropertyService();
    const contractService = new ContractService();

    export default {
        components: {
            Checkbox,
            Breadcrumb,
            BreadcrumbItem,
            [Option.name]: Option,
            [Select.name]: Select,
            [Tag.name]: Tag,
        },
        data() {
            return {
                breadcrumbs:[
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },

                    {
                        name: this.$t('contract.index.title'),
                        path: '/admin/contracts',
                        type: '',
                    },

                    {
                        name: this.$t('contract.new.header'),
                        path: '',
                        type: 'active',
                    }

                ],

                checkboxes: {
                    first: false,
                    second: false,
                    a: false,
                    b: false,
                    c: false,
                    unchecked: false,
                    checked: true,
                    disabledUnchecked: false,
                    disabledChecked: true
                },

                info_page:{
                    action: 'add',
                    header: this.$t('contract.new.title'),
                    button:  this.$t('contract.new.header'),
                    tab_lang: 'es',
                    langs:[{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    },{
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    show_array: false
                },

                fuseSearch: null,
                contract: {
                    _id: '',
                    cod: '',
                    meal_plan: '',
                    currency: '',
                    rooms:[],
                    payment_options: [],
                    link_rateplan: '',
                    link_value: '',
                    link_type: '',



                    es:{
                        name: '',

                    },
                    en:{
                        name: '',
                    }
                },
                contract_info: {
                    types: [
                        {
                            _id: 1,
                            name: 'Plan Europeo'
                        },
                        {
                            _id: 2,
                            name: 'Plan Europeo + Desayuno'
                        },
                        {
                            _id: 3,
                            name: 'Todo Incluido'
                        }
                    ],
                    contract_all:[],
                    payments: [
                        {
                            _id: 'TDD',
                            name: 'Tarjeta de crédito/debito'
                        },
                        {
                            _id: 'OXXO',
                            name: 'Depósito OXXO'
                        },
                        {
                            _id: 'PAYPAL',
                            name: 'PayPal'
                        },
                        {
                            _id: 'ARRIVE',
                            name: 'Pago en destino'
                        }
                    ],

                    format_payment:[
                        {
                            _id: '$',
                            name: '$',

                        },
                        {
                            _id: '%',
                            name: '%',

                        }
                    ],
                    currencys:[
                        {
                            _id: 'MXN',
                            name: 'MXN',

                        },
                        {
                            _id: 'USD',
                            name: 'USD',

                        }
                    ],
                    rooms:[
                        {
                            _id: '1',
                            name: 'Habitación estándar'
                        },
                        {
                            _id: '2',
                            name: 'Habitación doble'
                        },
                        {
                            _id: '3',
                            name: 'Habitación Jr. Suite'
                        }
                    ]
                },
                fplugin:{
                    rooms:{
                        array_list: [],
                        input_add: '',
                        input_status: false,
                        input_search: ''
                    },

                },

                modelValidations: {
                    name: {
                        required: true
                    },
                    description: {
                        required: true
                    },
                },


                options : {
                    shouldSort: true,
                    matchAllTokens: true,
                    threshold: 0.5,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name"
                    ]
                }
            }
        },

        mounted() {
            //this.fuseSearch = new Fuse(this.tableData, {keys: ['name']})
        },
        computed: {
            /***
             * Searches through table data and returns a paginated array.
             * Note that this should not be used for table with a lot of data as it might be slow!
             * Do the search and the pagination on the server and display the data retrieved from server instead.
             * @returns {computed.pagedData}
             */

        },
        created(){
          this.initPage();
        },
        methods: {
            initPage(){
                //console.log('router', this.$route.name );

                this.fplugin.rooms.array_list =  this.contract_info.rooms;


                //this.contract_info.contract_all = getContracts

                contractService.getContracts()
                    .then((response) => {
                       //console.log('rates_plans', response.data.data.rateplans);
                        //let rates_plans =;
                        this.contract_info.contract_all = response.data.data.rateplans;
                        //this.showSwal(this.$t('property.edit.msg'));

                    })
                    .catch(error => {
                       //console.log(error);
                    })
                    .finally(() => {
                    });




                let contract_id = this.$route.params.id;
               //console.log(contract_id, this.$route.name)
                if(this.$route.name==='editContract'){

                    this.info_page.action = 'edit';
                    this.info_page.header = this.$t('contract.edit.header');
                    this.info_page.button = this.$t('contract.edit.button');
                    this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t('contract.edit.header');

                    this.updateData(contract_id);
                }
            },
            updateData(contract_id) {
                contractService.getContractId(contract_id).then(response => {
                    let data = response.data.data.rateplan;

                    this.contract._id = data._id;
                    this.contract.cod= data.cod;
                    this.contract.meal_plan= data.meal_plan;
                    this.contract.currency= data.currency;
                    this.contract.rooms= data.rooms;
                    this.contract.payment_options= data.payment_options;
                    this.contract.link_rateplan= data.link_rateplan;
                    this.contract.link_value=data.link_value;
                    this.contract.link_type= data.link_type;

                   //console.log('propiedades', this.property);

                    this.contract.es.name = data.name;


                });
            },
            sendForm(action){

                this.validate(action);

            },
            saveItem(){
                contractService.createContract(this.contract)
                    .then((response) => {
                        this.showSwal(this.$t('contract.new.msg'));

                    })
                    .catch(error => {
                       //console.log(error);
                    })
                    .finally(() => {
                    });
            },
            updateItem(){
                contractService.updateContract(this.contract)
                    .then((response) => {
                       //console.log('property', response.data);
                        this.showSwal(this.$t('contract.edit.msg'));
                    })
                    .catch(error => {
                       //console.log(error);
                    })
                    .finally(() => {
                    });
            },
            showSwal (msg) {

                    swal({
                        title: this.$t('contract.new.modal'),
                        text: msg,
                        buttonsStyling: false,
                        confirmButtonClass: 'btn btn-success btn-fill',
                        type: 'success'
                    }).then(()=> {
                        this.$router.push({
                            name: 'Contracts'
                        });
                    });
            },
            getError (fieldName) {
                return this.errors.first(fieldName);
            },
            validate (action) {
                this.$validator.validateAll().then(isValid => {
                    if(isValid){
                        if(action==='add'){
                            this.saveItem();
                        }else{
                            this.updateItem();
                        }
                    }

                    //console.log('validado?', isValid)


                    //this.$emit('on-submit', this.registerForm, isValid)
                })
            },

            /******************* FUNCIONES PARA TEXTAREA MULTIPLE VERSION ADRIAN **************************************/

            handleInputConfirm () {
                /*let inputValue = this.tags.inputValue
                if (inputValue) {
                    this.tags.dynamicTags.push(inputValue)
                }
                this.tags.inputVisible = false
                this.tags.inputValue = ''*/
            },
            handleClose (index_ky, array_choose) {
                //let index_ky = this.findIndex(array_choose, id)

                array_choose.splice(index_ky, 1);
            },
            createinArray(text, array_choose){
                if(text.trim().length>0 && this.findName(array_choose, text)===-1){
                    array_choose.push(
                        {
                            _id: '',
                            name: text
                        }
                    )
                }
                return '';
            },
            addArray(contract, array_choose){
                //console.log('holaa si agrego', contract, array_choose, this.findIndex(array_choose, contract._id))
                //findIndex(array_choose, contract._id)
                if(this.findIndex(array_choose, contract._id)===-1){
                    array_choose.push(
                        {
                            _id: contract._id,
                            name: contract.name
                        }
                    )
                }


            },
            find_item(text, arrayList){
                //console.log('buscando item', text, arrayList);
                //this.queriedData(text, arrayList)
                var fuse = new Fuse(arrayList, this.options);
                var result = fuse.search(text);

                if(text===''){
                    result = arrayList;
                }
                //console.log('result', result);
                return result;

            },
            findIndex(arr, id) {
                let obj = arr.find(o => o._id === id);
                return  arr.indexOf(obj);
            },
            findName(arr, text) {
                let obj = arr.find(o => o.name === text);
                return  arr.indexOf(obj);
            }

            /**********************************************************************************************************/
        }
    }

</script>
<style lang="scss">
    .container-images {
        padding-top: 35px;
        .principal {
            width: 100%;
            height: 175px;
            margin-bottom: 10px;
        }
        .second{
            width: 30%;
            margin-left: 5%;
            &.first{
                margin-left: 0;
            }
        }
    }
    .container-tags{
        .box-inside{
            border: 2px solid #b9b9b9;
            position: relative;
            display: inline-block;
            width: 100%;
            .box-left{
                width: 50%;
                display: inline-block;
                border: 0;
                border-right: 2px solid #b9b9b9;
                float: left;
                input{
                    width: 100%;
                    height: 35px;
                    border: 0;
                    border-bottom: 2px solid #b9b9b9;
                    border-radius: 0;
                }
                .box-items{
                    height: 175px;
                    width: 100%;
                    overflow-y: scroll;
                    padding: 5px 0;
                    label{
                        cursor: pointer;
                        font-size: 16px;
                        line-height: 24px;
                        display: block;
                        text-transform: unset;
                        padding:0 10px;
                        margin: 0;
                        &:hover{
                            background-color: rgba(31, 118, 209, .5);
                            color: #fff;
                        }
                        &:active{
                            background-color: rgba(31, 118, 209, 1);
                            color: #fff;
                        }
                    }
                }

            }
            .box-right{
                width: 50%;
                display: inline-block;
                vertical-align: top;
                padding: 3px 0;
            }
        }

        .box-footer{
            padding-top: 10px;
            width: 100%;
            .box-left, .box-right{
                padding: 0 10px;
                width: 50%;
                display: inline-block;
                button{
                    width: 100% ;
                    height: 40px;
                    color: #fff;
                    background-color: #222222;
                    border: 2px solid #000;
                }
                input{
                    border-radius: 0;
                    width: 100%;
                    border-width: 2px;
                    height: 40px;
                }


            }

        }

    }
    .box-button{
        //padding-left: 10px;
        .add-item{
            width: 100% ;
            height: 40px;
            color: #fff;
            background-color: #222222;
            border: 2px solid #000;
        }
    }
    .btn-action {
        line-height: 45px;
        font-size: 25px;
        margin-left: 15px;
    }
    .card{
        .lbl-main{
            font-weight: 600;
            margin-top: 50px;
        }

        .pt-custom{
            margin-top: 26px !important;
        }

    }
    .el-tag, .el-tag.el-tag--default {
        background-color: #eee;
    }
</style>
